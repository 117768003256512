/* --------------------------------- */
/* Css Styles                        */
/*---------------------------------- */

/**
	Picard - vCard Html Template
	Author : TavillaThemes
	Copyright 2016

/* Table of Content
==================================================
	
	1. Body and Core Css
	2. Profile
	3. Top Menu
	4. Content
	5. About Page
	6. Resume Page
	7. Portfolio Page
	8. Blog Posts Page
	9. Contact Page
	10. Single Blog Page
	11. Responsive
	12. Sidebar
	


/*------------------------ 1 Body and Core Css ------------------------*/		

html {
	position: relative;
	min-height: 100%;
  }
  
  body{
	  font-family: 'Lato', sans-serif;
	  background:url(http://tavonline.net/html/picard/images/bg.png) #fafafa center fixed !important;
  }
  
  a{
	  text-decoration:none !important;
  }
  
  a:focus,
  a:active {
	  text-decoration: none;
	  outline: none;
  }
  
  p{
	  font-size:14px;
  }
  
  *:focus {
	  outline: 0;
  }
  
  #wrapper{
	  margin: 60px 0;
  }
  
  /*preloader*/
  
  #preloader {
	  background-color:#0b1623;
	  position: fixed;
	  top:0;
	  left:0;
	  width: 100%;
	  height: 100%; 
	  z-index: 99;
	  display:table;
  }
  
  .spinner {
	  margin: 0 auto;
	  width: 50px;
	  height: 30px;
	  text-align: center;
	  font-size: 10px;
	  display:table-cell;
	  vertical-align:middle;
  } 
  
  .spinner > div {
	  background-color: #ffffff;
	  height: 60px;
	  width: 6px;
	  display: inline-block; 
	  -webkit-animation: stretchdelay 1.2s infinite ease-in-out;
	  animation: stretchdelay 1.2s infinite ease-in-out;
  }
  
  .spinner .rect2 {
	  -webkit-animation-delay: -1.1s;
	  animation-delay: -1.1s;
  }
  
  .spinner .rect3 {
	  -webkit-animation-delay: -1.0s;
	  animation-delay: -1.0s;
  }
  
  .spinner .rect4 {
	  -webkit-animation-delay: -0.9s;
	  animation-delay: -0.9s;
  }
  
  .spinner .rect5 {
	  -webkit-animation-delay: -0.8s;
	  animation-delay: -0.8s;
  }
  
  @-webkit-keyframes stretchdelay {
	  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }  
	  20% { -webkit-transform: scaleY(1.0) }
  }
  
  @keyframes stretchdelay {
	  0%, 40%, 100% { 
	  transform: scaleY(0.4);
	  -webkit-transform: scaleY(0.4);
	}  20% { 
	  transform: scaleY(1.0);
	  -webkit-transform: scaleY(1.0);
	}
  }
  
  .parallax {
	  height: 417px;
	  position: absolute;
	  width: 100%;
	  background-attachment: inherit;
	  background-repeat: no-repeat;
	  background-size: cover;
	  top: 0;
  }
  
  /*------------------------ 2 Profile ------------------------*/		
  
  
  .profile-image{
	  width:100%;
	  -webkit-box-shadow: -2px -1px 88px 0px rgba(0,0,0,0.17);
	  -moz-box-shadow: -2px -1px 88px 0px rgba(0,0,0,0.17);
	  box-shadow: -2px -1px 88px 0px rgba(0,0,0,0.17);
  }
  
  .profile-image img{
	  width:100%;
  }
  
  .profile-info{
	  text-align:center;
	  background:#FFFFFF;
	  -webkit-box-shadow: -2px -1px 88px 0px rgba(0,0,0,0.17);
	  -moz-box-shadow: -2px -1px 88px 0px rgba(0,0,0,0.17);
	  box-shadow: -2px -1px 88px 0px rgba(0,0,0,0.17);
  }
  
  .profile-info h1{
	  font-size:20px;
	  font-weight:900;
  }
  
  .name-job{
	  padding:23px 0;
	  border-bottom:1px dashed #cccccc;
  }
  
  .job{
	  color:#c1c1c1;
	  font-weight:400;
	  line-height: 30px;
	  font-size:16px;
  }
  
  .profile-info .social-icons{
	  padding:20px;
  }
  
  
  .social-icons a{
	  font-size: 12px;
	  color: #2A2A2A;
	  border: solid 1px #2A2A2A;
	  -webkit-border-radius: 50px;
	  -moz-border-radius: 50px;
	  border-radius: 50px;
	  width: 34px;
	  display: inline-block;
	  padding: 8px 0;
	  margin-right: 5px;
	  text-align:center;
  }
  
  .social-icons a:hover{
	  transition:all ease 0.3s;
  }
  
  .social-icons a.facebook:hover{
	  color: #fff;
	  background:#4267b2;
	  border:solid 1px #4267b2;
  }
  
  .social-icons a.twitter:hover{
	  color: #fff;
	  background:#1da1f2;
	  border:solid 1px #1da1f2;
  }

  .social-icons a.linkedin:hover{
	color: #fff;
	background:#0077B5;
	border:solid 1px #0077B5;
  }

  .social-icons a.instagram:hover{
	color: #fff;
	background:#E1306C ;
	border:solid 1px #E1306C ;
  }
  
  .social-icons a.behance:hover{
	  color: #fff;
	  background:#005cff;
	  border:solid 1px #005cff;
  }
  
  .social-icons a.dribbble:hover{
	  color: #fff;
	  background:#ea4c89;
	  border:solid 1px #ea4c89;
  }
  
  
  
  /*------------------------ 3 Top Menu ------------------------*/	
  .top-menu a {
	float: left;
    border-right: 1px dashed #cccccc;
    padding: 30px;
	text-transform: uppercase;
	color: #000;
  }
  .top-menu a.selected {
	  border-bottom: 3px solid #ffa60a;
  }
  .top-menu a.selected i, .top-menu a.selected span {
    color: #ffa60a;
  }
  .top-menu a:last-child {
	border-right: none;
  }
  ul.top-menu{
	  width: 100%;
	  background:#fff;	
	  display: inline-block;
	  -webkit-box-shadow: -2px -1px 88px 0px rgba(0,0,0,0.17);
	  -moz-box-shadow: -2px -1px 88px 0px rgba(0,0,0,0.17);
	  box-shadow: -2px -1px 88px 0px rgba(0,0,0,0.17);
	  padding-left: 0px;
  }

  ul.top-menu li span, ul.top-menu li i{
	  color:#000000;
  }
  
  ul.top-menu li{
	  list-style:none;
	  float:left;
	  text-align:center;
	  font-size:13px;
	  font-weight: bold;
	  border-right:1px dashed #cccccc;
	  padding:30px;
  }
  
  
  .right-menu{
	  float: right;
	  margin: 15px;
	  padding: 15px;
	  border: solid 1px #d6d6d6;
	  color: #292929;
	  cursor:pointer;
	  position: absolute;
	  right: 15px;
  }
  
  ul.top-menu li:last-child{
	  border-right:0;
  }
  
  ul.top-menu li a{
	  color:#000;
	  padding:30px;	
	  display: inline-block;	
	  display: block;
  }
  
  ul.top-menu li a:hover{
	  transition:all ease 0.2s;
  }
  
  ul.top-menu li i{
	  font-size:14px;
	  margin-right: 4px;
  }
  
  /*------------------------ 4 Content ------------------------*/	
  
  .content{
	  background:#fff; 
	  margin-top:30px;
	  -webkit-box-shadow: -2px -1px 88px 0px rgba(0,0,0,0.17);
	  -moz-box-shadow: -2px -1px 88px 0px rgba(0,0,0,0.17);
	  box-shadow: -2px -1px 88px 0px rgba(0,0,0,0.17);
  }
  
  .page-title{
	  border-bottom: 1px dashed #cccccc;		
	  padding: 25px 30px;
  }
  
  .page-title h2{
	  font-size:18px;
	  font-weight:bold;
	  display:inline-block;
  }
  
  .page{
	  padding-top:30px;
	  display: inline-block;
	  min-height: 670px;
  }
  
  .section-title{
	  font-size:18px;
	  font-weight:bold;
	  margin-bottom:20px;
  }
  
  .footer{
	  bottom: 0;
	  width: 100%;
	  text-align: center;
	  padding: 27px 0;
	  float: left;
	  left: 0;
	  border-top: 1px dashed #cccccc;
  }
  
  .footer p {
	  color:#b4b4b4;
	  font-size:13px;
  }
  
  /*------------------------ 5 About Page ------------------------*/	
  
  
  
  .pinfo{
	  padding: 30px 15px;
	  float:left;
	  width:100%
  }
  
  .ptext{
	  padding: 45px 15px;
	  float:left;
	  width:100%
  }
  
  .pwedo{
	  padding: 45px 15px 20px 15px;
	  float:left;
	  width:100%
  }
  
  .spotted{
	  border-top: 1px dashed #cccccc;
  }
  
  .gray-bg{
	  background:#f7f7f7;
  }
  
  
  
  ul.info-list li{
	  list-style:none;
	  font-size:14px;
	  line-height: 35px;
  }
  
  ul.info-list li span{
	  color:#7F7F7F;
  }
  
  ul.skill-list h3 {
	  display: block;
	  margin-bottom: 0;
	  font-size: 14px;
	  color: #000;
	  font-weight:bold;
  }
  
  ul.skill-list li {
	  list-style:none;
  }
  
  .progress{
	  margin: 15px 0;
	  display: block;
	  height: 6px;    
	  position: relative;
	  width: 100%;
	  overflow: visible;
	  border-radius: 50px;
	  -moz-border-radius: 50px;
	  -webkit-border-radius: 50px;
	  -ms-border-radius: 50px;
	  -o-border-radius: 50px;
	  background: #ffffff;
	  box-shadow:0;
  }
  
  .progress .percentage {
	  border-radius: 50px 0 0 50px;
	  -moz-border-radius: 50px 0 0 50px;
	  -webkit-border-radius: 50px 0 0 50px;
	  -ms-border-radius: 50px 0 0 50px;
	  -o-border-radius: 50px 0 0 50px;
	  bottom: 0;
	  position: absolute;
	  top: 0;
  }
  
  
  .about-text p{
	  color:#7F7F7F;
	  line-height:24px;
	  font-size:14px;
  }
  
  .services {
	  display: inline-block;
  }
  
  .services .service i{
	  width: 55px;
	  height: 55px;
	  -webkit-border-radius: 50px;
	  -moz-border-radius: 50px;
	  border-radius: 50px;
	  text-align: center;
	  padding-top: 18px;
	  float: left;
	  color:#fff;
	  font-size:20px;
	  margin-right: 10px;
  }
  
  .services .service span{
	  padding-top: 6px;
	  display: inline-block;
	  line-height: 20px;	
	  font-size:16px;
  }
  
  .services .service p{
	  font-size:14px;
	  color:#7F7F7F;
  }
  
  .services .service{
	  margin-bottom:30px;
  }
  
  
  
  /*------------------------ Resume Page ------------------------*/	
  
  .resume-education{
	  margin-bottom:30px;
  }
  
  .rounded-icon i{
	  width:55px;
	  text-align:center;
	  padding:20px 0;
	  font-size:15px;
	  color:#fff;
	  -webkit-border-radius: 50px;
	  -moz-border-radius: 50px;
	  border-radius: 50px;
  }
  
  .resume-out{
	  padding-left: 25px;
  }
  
  .resume-info{
	  border-left:2px solid #E5E5E5;
	  padding: 0 0 40px 30px;
  }
  
  .info-title{
	  font-weight: 600;
	  font-size:16px;
  }
  
  .info-title::after {
	  content: '';
	  width: 10px;
	  height: 10px;
	  float: left;
	  -webkit-border-radius: 50px;
	  -moz-border-radius: 50px;
	  border-radius: 50px;
	  margin: 0px 9px 0 -36px;
  }
  
  .info-date{
	  font-size: 14px;
	  padding: 6px 0;
	  display: block;
  }
  
  .resume-info p{
	  color: #7F7F7F;
	  font-weight: 400;
	  font-size:14px;
	  line-height:24px;
  }
  
  .resume-info:first-child {
	  padding-top:50px;
  }
  
  .resume-info:last-child{
	  border: 0;
  }
  
  .resume-info:last-child .info-title:after{
	  margin: 0px 9px 0 -34px;
  }
  
  
  /*------------------------ 7 Portfolio Page ------------------------*/
  
  .portfolio_items{
	  min-height:816px;
  }
  
  .portfolio_filter li{
	  float:left;
	  list-style:none;
	  cursor:pointer;
	  font-size:14px;
	  margin-right:20px;
	  color:#c1c1c1;
  }
  
  .portfolio_filter li:last-child{
	  margin-right:0px;
  }    
  
  .portfolio_items .single_item{
	  margin-bottom:30px;
  }
  
  .portfolio_items .single_item img{ 
	  width:100%;
  }
  
  
  .work-overlay{
	  position:absolute;
	  color:#fff;
	  padding:25px;
	  width:100%;
	  height:100%;
	  padding-top:48%;	
	  opacity:0;
	  -webkit-transition: all 0.5s ease;
	  -moz-transition: all 0.5s ease;
	  -ms-transition: all 0.5s ease;
	  -o-transition: all 0.5s ease;
	  transition: all 0.5s ease;
  }
  
  .work-overlay h3{
	  font-size:17px;
	  margin-bottom: 4px;
  }
  
  .work-overlay p{
	  font-size:12px;
	  opacity:0.5;
  }
  
  .work-overlay a.link{
	  margin: 0 auto;
	  text-align: center;
	  display: inline-block;
	  width: 45px;
	  height: 45px;
	  padding-top: 12px;
	  margin-top: 18px;
	  margin-right: 7px;
	  -webkit-transition: all 0.5s ease;
	  -moz-transition: all 0.5s ease;
	  -ms-transition: all 0.5s ease;
	  -o-transition: all 0.5s ease;
	  transition: all 0.5s ease;
	  -moz-transform: translateY(200px);
	  -webkit-transform: translateY(200px);
	  -o-transform: translateY(200px);
	  -ms-transform: translateY(200px);
	  transform: translateY(200px);
	  color: #ffffff !important;
	  border: solid 1px;
	  border: solid 1px #a5a5a5;       
  }
  
  .work-overlay a.lightbox{
	  background: none;
	  margin: 0 auto;
	  text-align: center;
	  display: inline-block;
	  width: 45px;
	  height: 45px;
	  padding-top: 12px;
	  margin-top: 18px;
	  -webkit-transition: all 0.5s ease;
	  -moz-transition: all 0.5s ease;
	  -ms-transition: all 0.5s ease;
	  -o-transition: all 0.5s ease;
	  transition: all 0.5s ease;
	  -moz-transform: translateY(200px);
	  -webkit-transform: translateY(200px);
	  -o-transform: translateY(200px);
	  -ms-transform: translateY(200px);
	  transform: translateY(200px);
	  color: #ffffff !important;
	  background: transparent !important;
	  border: solid 1px #a5a5a5;
  }
  
  
  
  .work-inner{
	  position:relative;
	  overflow: hidden;
  }
  
  .work-inner:hover > .work-overlay{ 
	  background: rgba(0, 0, 0, 0.63);
	  opacity:1;
	  -webkit-transition: all 0.5s ease;
	  -moz-transition: all 0.5s ease;
	  -ms-transition: all 0.5s ease;
	  -o-transition: all 0.5s ease;
	  transition: all 0.5s ease;
  }
  
  
  .work-inner:hover > .work-overlay a.link{ 
	  color:#fff;
	  -moz-transform: translateY(0px);
	  -webkit-transform: translateY(0px);
	  -o-transform: translateY(0px);
	  -ms-transform: translateY(0px);
	  transform: translateY(0px);
	  -webkit-transition: all 0.1s ease;
	  -moz-transition: all 0.1s ease;
	  -ms-transition: all 0.1s ease;
	  -o-transition: all 0.1s ease;
	  transition: all 0.1s ease;
	   transition-duration: 0.3s;
  }
  
  .work-inner:hover > .work-overlay a.lightbox{ 
	  color:#fff;
	  background:#fff;
	  -moz-transform: translateY(0px);
	  -webkit-transform: translateY(0px);
	  -o-transform: translateY(0px);
	  -ms-transform: translateY(0px);
	  transform: translateY(0px);
	  -webkit-transition: all 0.1s ease;
	  -moz-transition: all 0.1s ease;
	  -ms-transition: all 0.1s ease;
	  -o-transition: all 0.1s ease;
	  transition: all 0.1s ease; 
	  transition-duration: 0.6s;
  }
  
  .work-detail{ 
		 margin-bottom:30px;
  }
  
  .work-detail p{ 
	  color: #7F7F7F;
	  font-size: 14px;
	  line-height: 30px;
	  margin-bottom: 21px;
  }
  
  .works-image img{ 
	  width:100%;
  }
  
  .works-image {
	  margin-bottom: 30px;
  }
  
  
  /*------------------------ 8 Blog Posts Page ------------------------*/
  
  .blog-post .post-image img{
	   width:100%;
  }
  
  .blog-post .post-title h1{
	  font-size:20px;
	  line-height:24px;
	  font-weight:bold;
	  margin-top:24px;
  }
  
  .blog-post .post-info{
	  font-size:14px;
	  line-height:24px;
	  font-weight:600;
	  padding:6px 0;
  }
  
  .blog-post p{
	  font-size:14px;
	  line-height:24px;
	  color:#7F7F7F;
	  font-weight:400;
  }
  
  .blog-post .but-big{
	  margin:30px 0 60px;
  }
  
  .but-big{
	  color:#fff;
	  font-size:13px;
	  font-weight:bold;
	  padding:12px 25px;
	  display: inline-block;
	  border:0;
  }
  
  .but-big:hover{
	  color:#fff;
  }
  
  .but-big:focus{
	  color:#fff;
  }
  
  
  /*------------------------ 9 Contact Page ------------------------*/
  
  .form-inp{
	  width:100%;
	  height:45px;
	  border:solid 1px #dfdfdf;
	  font-size:13px;
	  padding-left:10px;
	  margin-bottom:15px;
  }
  
  
  .contact-form textarea{
	  width:100%;
	  border:solid 1px #dfdfdf;
	  font-size:13px;
	  padding:10px 0 0 10px;
	  margin-bottom:15px;
  }
  
  
  .contact-info .social-icons{
	  padding-top: 15px;
  }
  
  #map{
	  width: 100%;
	  height: 350px;
	  margin-top: 50px;
	  display:inline-block;
  }
  
  /*------------------------ 10 Single Blog Page ------------------------*/
  
  .post-comments{
	  margin:50px 0;
  }
  
  .post-comments .comments li{
	  border-bottom: 1px dashed #cccccc;
	  list-style:none;
	  padding: 25px 0;
  }
  
  .post-comments .comments li:first-child{
	  border-top: 1px dashed #cccccc;
  }	
  
  .user-img{
	  width:84px;
	  height:84px;
	  float:left;
  }
  
  .user-img img{
	  display: block;
	  height: 100%;
	  max-width: 100%;
  }
  
  .comment-content{
	  margin-left:95px;
  }
  
  .comment-content .comment-info .user a.user-name{
	  font-size:16px;
	  color:#000000;
	  font-weight:bold;
  }
  
  .comment-content .comment-info .user a.reply{
	  float: right;
	  font-size: 13px;
  }
  
  .comment-content .comment-info .date{
	  line-height: 14px;
  }
  
  .comment-content .comment-info .date a{
	  font-size:12px;
  }
  
  .comment-content .comment-text{
	  font-size: 14px;
	  line-height: 22px;
	  color: #7F7F7F;
	  font-weight: 400;
  }
  
  .blog-msg{
	  width:100%;
	  height:45px;
	  border:solid 1px #dfdfdf;
	  font-size:13px;
	  padding-left:10px;
	  margin-bottom:30px;
  }
  
  .write-message textarea{
	  width: 100%;
	  border: solid 1px #dfdfdf;
	  font-size: 13px;
	  padding: 10px 0 0 10px;
	  margin-bottom: 15px;
  }
  
  
  @media screen and (min-width: 479px) {
	  
	  .comment-reply{
		  padding-left:30px !important;
	  }
	  
  }
  
  
  /*------------------------ Responsive ------------------------*/	
  
  
  
  /*Min Width 768px */
  
  @media screen and (min-width: 768px) {
  
	   ul.info-list li {
		  float: left;
		  width: 33%;
	  }
  
  
	   .contact.info-list li  {
		  float: left;
		  width: 100%;
	  }
  
	   .contact.info-list {
		  margin-bottom:15px;
	  }
	  
	  ul.info-list {
		  width: 100%;
		  float: left;
  }
	  
	
	  ul.top-menu li{
		  display: block !important;
				  
  }
	  
	  
  
  }
  
  @media screen and (max-width: 768px) and (min-width: 530px) {
  
  
   ul.info-list li {
		  float: left;
		  width: 50%;
	  }
  
  
  }
  
  
  
  /*Max Width 1199px */
  
  @media screen and (max-width: 1199px) {
	  
  
	  ul.top-menu li a {
		  padding:30px 20px;
	  }
  
  }
  
  /*Max Width 991px */
  
  @media screen and (max-width: 991px) {
  
	  .profile{
		  margin-bottom: 30px;
	  }
	  
	  .my-skills {
		  margin-top:30px;	
	  }
	  
	  .contact-info{
		  margin-top: 30px;
	  }
	  
	  
  }
  
  
  /*Max Width 767px */
  
  @media screen and (max-width: 767px) {
	  
	  ul.top-menu li.active{
		  border-bottom: 1px dashed #cccccc !important;
				  
  }
	  
	  ul.top-menu li:hover{
	  border-bottom: 1px dashed #cccccc !important;
  
  }
	  
	  .profile{
		  margin-bottom: 30px;
	  }
	  
	  .my-skills {
		  margin-top:30px;	
	  }
	  
	  .services .service span{
		  width:100%;
		  text-align: center;
	  }
	  
	  .services .service{
		  text-align:center;
	  }
	  
	  .services .service i{
		  float:none;
	  }
  
  
	  ul.top-menu li{
		  float:none;
		  display:none;
		  border-bottom: 1px dashed #cccccc;
		  border-right:0;
	  }
	  
	  ul.top-menu{
		  top: 0;
		  margin-top: 0;
		  z-index: 1;
		  left: 0;
		  position: fixed;
	  }
	  
	  
	  .responsive-menu{
		  display:block !important;
	  }
  
  
	  .right-menu{
		  position: fixed;
		  z-index: 100;
		  top: 0;
		  right: 0;
	  }
	  
  }
  
  
  @media only screen and (max-width:479px) {
	  
	  
	  
	  ul.top-menu li a {
		  padding: 27px 0 23px 0;
	  }
	  
	  #wrapper {
			margin: 15px 0;
	  }
	  
	  .page-title {
		  border-bottom: 1px dashed #cccccc;
		  padding: 30px;
		  display: inline-block;
		  text-align: center;
		  width: 100%;
	  }
	  
	  .portfolio_filter li {
		  float: none;
		  text-align: center;
		  width: 100%;
	  }
	  
	  .portfolio_filter{
		  width: 100%;
		  margin-top: 15px;
		  line-height: 26px;
	  }
	  
	  .user-img{ 
		  display:none; 
	  }
	  
	  .comment-content {
			 margin-left: 0px;
	  }
	  
	  .work-overlay{
			 padding-top: 10% !important;
	  }
	  
  }
  
  
  /* Contact Form Extra CSS */
  .form-inp.reqError, .contact-form textarea.reqError{
	  border-color: #f00;
  }
  
  .ok{
	 background-color: #25A94F !important;
  }
  
  
  
  
  /* Sidebar Menu */
  
  .slidemenu-fixed {
	position: fixed;
	z-index: 100;
	top: 0;
	right: 0;
	width: 360px;
	height: 100%;
	margin: 0;
	background-color: #fff;
	-webkit-transform: translateX(450px);
	-moz-transform: translateX(450px);
	transform: translateX(450px);
	-webkit-transition: -webkit-transform 0.4s cubic-bezier(0.37, 0.15, 0.32, 0.94);
	-moz-transition: -moz-transform 0.4s cubic-bezier(0.37, 0.15, 0.32, 0.94);
	transition: transform 0.4s cubic-bezier(0.37, 0.15, 0.32, 0.94); 
}
	
   
	.slidemenu-fixed .animate-up {
	  opacity: 1;
	  visibility: visible;
	  -webkit-transform: translate(0, 0);
	  -moz-transform: translate(0, 0);
	  transform: translate(0, 0); 
	}
  
  .slidemenu-opened .slidemenu-fixed {
	-webkit-transform: translateX(0);
	-moz-transform: translateX(0);
	transform: translateX(0); 

}
  .slidemenu-opened #overlay {
	top: 0;
	opacity: .7;
	-webkit-transition: top 0s ease 0s, opacity 0.35s ease;
	-moz-transition: top 0s ease 0s, opacity 0.35s ease;
	transition: top 0s ease 0s, opacity 0.35s ease; 
}
  
  
  .widget-out {
	  height: 100%;
	  overflow: hidden;
	  position: relative;
  }
  
  .widget{
		  float: left;
	  width: 100%;
  }
  
  
  @media (max-width: 767px) {
	.slidemenu-fixed {
	  width: 265px; }
  
	.slidemenu-close {
	  left: -35px !important;
	  width: 35px !important;
	  height: 35px !important; } 
	  
  
  .widget-search form{ 
	  width: 215px !important; 
	}
	
	.widget-search form .search-inp{ 
	  width: 165px !important; 	  
	}
	
  }
	  
	  .slidemenu-close {
	  top: 70px;
	  left: -58px;
	  width: 58px;
	  height: 58px;
	  border: 0;
	  margin: 0;
	  padding: 0;
	  display: block;
	  cursor: pointer;
	  text-align: center;
	  position: absolute;	
	  z-index: 100;
  }
  
  
	#overlay {
	position: fixed;
	z-index: 99;
	top: -1000%;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background-color: #000;
	opacity: 0;
	-webkit-transition: top 0s ease 0.35s, opacity 0.35s ease;
	-moz-transition: top 0s ease 0.35s, opacity 0.35s ease;
	transition: top 0s ease 0.35s, opacity 0.35s ease; 
}
	
	
	.widget-title{
	  font-size: 18px;
	  font-weight: bold;
	  padding-bottom: 20px;
	  margin-bottom: 20px;
	  border-bottom: 1px dashed #cccccc;
	  border-top: 1px dashed #cccccc;
	  padding: 20px 25px;
	}
	
	.widget-submenu{ 
		margin: 30px 0;
	}
	
	.widget-submenu ul{ 
		padding-left:25px;
	}
	
	.widget-submenu ul li{ 
		color: #6a6a6a;
	  font-size: 14px;
	  line-height: 35px;
	  cursor:pointer;  
	}
	
	
	.widget-search form{ 
	  border: solid 1px #efefef;
	  transition:all ease 0.5s;
	  width: 310px;
	  margin-left: 25px;
	  margin-top:30px;
	  height: 52px;	
	  border: solid 1px #bdc4ca;	  
	}
   
	.widget-search form .search-inp{ 
		border:0;
	  width: 260px;
	  float:left;
	  height: 50px;
	  font-size:13px;
	  padding-left:15px;	  
	}
	
	
	.widget-search form .search-ico{ 
		float:right;	  
	  border: 0;
	  background: url(http://tavonline.net/html/picard/images/sear.png) no-repeat center #fff;
	  margin-right: 15px;
	  margin-top:14px;
	  width: 14px;
	  height: 23px;
	}
	
	.widget-blog .latest-post{ 
		margin:0 25px;
	  float: left;
	}
	
	.widget-blog .latest-post .tag a{ 
		font-size:12px;
	   margin-right: 3px;
	}
	
	.widget-blog .latest-post .post{ 
		font-size: 13px;
	  float: left;
	  color: #6a6a6a;
	  margin: 7px 0px;
	  line-height: 22px;
	}
	
   .widget-blog .latest-post li{ 
	  border-bottom: 1px solid #e0e0e0;
	  float: left;
	  padding-bottom: 18px;
	  margin-bottom: 18px;
	}
  
  .widget-blog .latest-post li:last-child{ 
	  border:0;
		  margin-bottom: 0;
	}
  
  .widget-blog .latest-post li span{ 
	  font-size:12px;
	  color:#c1c1c1;
	}
	
  .widget-blog .latest-post li span i{ 
	  margin-left:10px;
	}
  
  .widget-tags{
	  margin-bottom:30px;
	}
	
  .widget-tags ul{
	  margin-left:25px; 
	}
	
  .widget-tags ul li a{
	  padding: 5px 11px;
	  font-size: 12px;
	  background: #f3f3f3;
	  float: left;
	  margin: 5px;
	  color:#676767;
	}
  
  
  /* Responsive Menu */
  
  .responsive-menu{
	  padding: 28px 23px;
	  color: #fff;
	  cursor: pointer;
	  display: none;
	  background: #fff;
	  color: #000;
	  font-size: 14px;
	  font-weight: bold;
	  width: 100%;
	  position: fixed;
	  top: 0;
	  z-index: 99;
	  left: 0;
		  -webkit-box-shadow: -2px -1px 88px 0px rgba(0,0,0,0.17);
	  -moz-box-shadow: -2px -1px 88px 0px rgba(0,0,0,0.17);
	  box-shadow: -2px -1px 88px 0px rgba(0,0,0,0.17);
  }
  